<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="Information"
  >
    <vbt-table
      :headers="headers"
      :items="locations"
      :show-edit-action="hadAdminRole"
      @click:edit="goToLocation"
    />
  </vbt-content-box>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import { AuthNamespace, AppLocationsNamespace } from '@store/types';

import {
  namespace as LocationsNamespace,
  ActionTypes as LocationsActionTypes,
} from '../store.types';

const dataLoadingOptions = {
  getterName: LocationsActionTypes.GET_LOCATIONS,
  contentName: 'locations',
  searchable: false,
  paged: false,
};

const headers = Object.freeze([
  { text: 'Name', value: 'name' },
  { text: 'Suffix', value: 'suffix' },
]);

export default {
  name: 'DsbLocations',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  data() {
    return {
      headers,
    };
  },

  computed: {
    ...mapState(AppLocationsNamespace, ['suffix']),

    ...mapState(LocationsNamespace, ['locations']),

    ...mapGetters(AuthNamespace, ['hadAdminRole']),
  },

  methods: {
    ...mapActions(LocationsNamespace, [LocationsActionTypes.GET_LOCATIONS]),

    goToLocation({ suffix }) {
      this.$router.push({
        name: 'location',
        query: {
          suffix: this.suffix,
        },
        params: {
          suffix,
        },
      });
    },
  },
};
</script>
